import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import InfoSVG from '../../assets/info.svg';
import ShareSVG from '../../assets/share.svg';
import css from './Tooltips.module.css';

const Tooltips = props => {
  const { noPadding } = props
  return (
    <div className={classNames(css.tooltip, !noPadding && css.additionalPadding)}>
      <img src={InfoSVG} width={12} height={12} />
      <span className={css.tooltiptext}>
        <div className={css.tooltipTitle}>
          <FormattedMessage id="CreateTalentSpecificsForm.ToolTip" />
        </div>
        <div className={classNames(css.tooltipSub, css.tooltipSubBold, css.paddingTop)}>
          <FormattedMessage id="Tooltips.new" />
        </div>
        <div className={classNames(css.tooltipSub)}>
          <FormattedMessage id="Tooltips.newDescription" />
        </div>
        <div className={classNames(css.tooltipSub, css.tooltipSubBold)}>
          <FormattedMessage id="Tooltips.usedNew" />
        </div>
        <div className={classNames(css.tooltipSub)}>
          <FormattedMessage id="Tooltips.usedNewDescription" />
        </div>
        <div className={classNames(css.tooltipSub, css.tooltipSubBold)}>
          <FormattedMessage id="Tooltips.usedgood" />
        </div>
        <div className={classNames(css.tooltipSub)}>
          <FormattedMessage id="Tooltips.usedgoodDescription" />
        </div>

        {/* <div className={css.tooltipPadding}>
          <a
            target="_blank"
            href={window.location.origin + '/p/faq#paddle-condition'}
            className={classNames(css.tooltipButton)}
          >
            <div className={css.tooltipButtonTitle}>
              <FormattedMessage id="Tooltips.learnmore" />{' '}
            </div>
            <img src={ShareSVG} width={12} height={12} />
          </a>
        </div> */}
      </span>
    </div>
  );
};

export default Tooltips;
